<template>
  <v-container class="pa-0">
    <v-form class="mt-3" ref="form" v-model="valid">
      <v-row class="px-2">
        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Nama Lengkap
          </span>
          <v-text-field
            outlined
            class="pt-1"
            autocomplete="off"
            placeholder="Cth. Muhamad Fadli"
            v-model="dataTransaksi.detail_transaksi.name"
            :rules="[
              () =>
                !!dataTransaksi.detail_transaksi.name ||
                'Name tidak boleh kosong',
            ]"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Nomor Handphone
          </span>
          <v-text-field
            outlined
            class="pt-1 inputNumber"
            type="number"
            placeholder="Cth. 081234567890"
            autocomplete="off"
            hint="Cth. 081234567890"
            v-model="dataTransaksi.detail_transaksi.no_hp"
            :rules="[
              () =>
                !!dataTransaksi.detail_transaksi.no_hp ||
                'Nomor Ponsel tidak boleh kosong',
              () =>
                (!!dataTransaksi.detail_transaksi.no_hp &&
                  dataTransaksi.detail_transaksi.no_hp.length <= 13) ||
                'Max 13 angka',
              () =>
                !!dataTransaksi.detail_transaksi.no_hp &&
                dataTransaksi.detail_transaksi.no_hp.length >= 11,
            ]"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Alamat Jemput
          </span>
          <v-textarea
            outlined
            class="pt-1"
            placeholder="Jalan Bakti no 25D"
            autocomplete="off"
            rows="2"
            v-model="dataTransaksi.detail_transaksi.alamat_jemput"
            :rules="[
              () =>
                !!dataTransaksi.detail_transaksi.alamat_jemput ||
                'Alamat tidak boleh kosong',
            ]"
          >
          </v-textarea>
        </v-col>

        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Catatan Penjemputan
          </span>
          <v-textarea
            outlined
            class="pt-1"
            placeholder="Tulis patokan alamat disini"
            autocomplete="off"
            rows="2"
            v-model="dataTransaksi.detail_transaksi.catatan_alamat"
            :rules="[
              () =>
                !!dataTransaksi.detail_transaksi.catatan_alamat ||
                'Catatan Alamat tidak boleh kosong',
            ]"
          >
          </v-textarea>
        </v-col>

        <v-col cols="6" class="py-0">
          <v-menu
            v-model="menuTgl"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="text-subtitle-2 font-weight-bold gray--text">
                Tanggal Jemput
              </span>
              <v-text-field
                v-model="dataTransaksi.detail_transaksi.tgl_jemput"
                :rules="[
                  () =>
                    !!dataTransaksi.detail_transaksi.tgl_jemput ||
                    'Tanggal Jemput tidak boleh kosong',
                ]"
                append-icon="mdi-calendar"
                placeholder="tanggal jemput"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataTransaksi.detail_transaksi.tgl_jemput"
              :min="date"
              @input="menuTgl = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Waktu Jemput
          </span>
          <v-select
            outlined
            placeholder="pilih waktu"
            :items="waktu"
            item-text="text"
            item-value="value"
            v-model="dataTransaksi.detail_transaksi.waktu_jemput"
            :rules="[
              () =>
                !!dataTransaksi.detail_transaksi.waktu_jemput ||
                'Waktu Jemput tidak boleh kosong',
            ]"
          >
          </v-select>
        </v-col>

        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Tambah Alamat via Google Map
          </span>
          <v-row class="mx-1 pt-1 mb-2" @click="locatorButtonPressed">
            <v-col cols="2" class="px-0">
              <v-avatar size="30" color="#0C84F3">
                <v-icon size="20" dark> mdi-map-marker </v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="10" class="px-0">
              <v-text-field
                color="primary"
                v-model="position"
                readonly
                dense
                placeholder="klik untuk menambahkan koordinat"
                hint="aktifkan GPS anda untuk mengunci koordinat"
                persistent-hint
                :rules="[() => !!position || 'Koordinat tidak boleh kosong']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="py-0">
          <span class="text-subtitle-2 font-weight-bold gray--text">
            Tambahkan Foto
          </span>
          <div class="wrapper-add-image">
            <input
              class="form-input-file"
              required
              type="file"
              name="image"
              id="image"
              @change="onFileChange"
            />

            <div v-if="url" class="preview">
              <img :src="url" />
            </div>

            <div v-else>
              <v-icon class="add-photo" large>mdi-plus</v-icon>
            </div>
          </div>
          <v-alert class="my-2" dense type="error" v-model="alert">
            Foto tidak boleh kosong
          </v-alert>
        </v-col>

        <v-col cols="12" class="my-5">
          <v-btn
            large
            :loading="loading"
            :disabled="!valid"
            block
            class="white--text"
            style="text-transform: unset !important"
            color="#EB4646"
            @click.prevent="procced"
          >
            Lanjutkan
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="alert" persistent class="rounded-lg" max-width="450">
      <v-card tile elevation="0" class="rounded-lg pb-3">
        <v-toolbar dense tile elevation="0">
          <v-btn small icon @click="alert = !alert">
            <v-icon small color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-subtitle class="py-4 text-subtitle-2 black--text">
          {{ title }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            depressed
            block
            dark
            color="#EB4646"
            style="text-transform: unset !important"
            @click="alert = !alert"
          >
            Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    valid: false,
    alert: false,
    title: "",
    loading: false,
    menu2: false,
    menuTgl: false,
    url: null,
    image: "",
    position: "",
    waktu: [
      { value: "Pagi", text: "Pagi" },
      { value: "Siang", text: "Siang" },
      { value: "Sore", text: "Sore" },
    ],
    dataTransaksi: {
      id_user: "999999",
      jenis_transaksi: 1,
      status: 1,
      detail_transaksi: {
        id_pemol: "PML00000017",
        photo: null,
        metode_bayar: "Cash",
        status: "1",
        name: "",
        no_hp: "",
        voucher: null,
        detail_nasabah: [],
        latitude: "",
        distance: 0,
        longitude: "",
        alamat_jemput: "",
        catatan_alamat: "",
        tgl_jemput: "",
        waktu_jemput: "",
        waktu_selesai: null,
        waktu_batal: null,
        riwayat_transaksi: [],
        rating: "0",
        biaya: "",
        max_biaya: "",
        komentar: null,
        catatan: null,
        on_job: "0",
      },
    },
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    ...mapActions({
      setDataTransaksi: "dataOrder/setDataTransaksi",
    }),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = `${position.coords.latitude},${position.coords.longitude}`;
        },
        (error) => {
          console.log(error.message);
        }
      );
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.dataTransaksi.detail_transaksi.photo = file.name;
      this.createImage(file);
    },

    createImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;

        imgElement.onload = (e) => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 400;

          const scaleSize = MAX_WIDTH / e.target.width;
          canvas.width = MAX_WIDTH;
          canvas.height = e.target.height * scaleSize;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

          const srcEncoded = ctx.canvas.toDataURL(e.target.result);
          localStorage.setItem("img", srcEncoded);
        };
      };
    },

    procced() {
      this.alert = false;
      var xyz = this.position.split(",");

      this.dataTransaksi.detail_transaksi.latitude = xyz[0];
      this.dataTransaksi.detail_transaksi.longitude = xyz[1];

      var firstChar = this.dataTransaksi.detail_transaksi.no_hp.charAt(0);
      var newPhoneNumber = "";
      if (firstChar === "0") {
        newPhoneNumber =
          "+62" + this.dataTransaksi.detail_transaksi.no_hp.substring(1);
      } else if (firstChar === "8") {
        newPhoneNumber = "+62" + this.dataTransaksi.detail_transaksi.no_hp;
      } else {
        this.alert = true;
        this.title = "Format Nomor HP Tidak Tepat..";
        return;
      }

      this.dataTransaksi.detail_transaksi.no_hp = newPhoneNumber;

      if (this.dataTransaksi.detail_transaksi.photo === null) {
        this.alert = true;
      } else {
        this.setDataTransaksi(this.dataTransaksi);
        this.$router.push({ name: "detail_order" });
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    },
  },
  created() {
    localStorage.removeItem("img");
    localStorage.removeItem("dataTransaksi");
  },
};
</script>

<style>
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
<style scoped>
.form-input-file {
  position: relative;
  z-index: 2;
  padding: 16px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  color: transparent;
  cursor: pointer;
}

.form-input-file::-webkit-file-upload-button {
  visibility: hidden;
}

.form-input-file::before {
  content: "";
  display: inline-block;
}

.preview {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview img {
  width: 100%;
  height: 100%;
}

.wrapper-add-image {
  position: relative;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  height: 72px;
  width: 72px;
}

.wrapper-image {
  padding: 8px;
  background-color: #0c84f3;
  border-radius: 10px;
  width: fit-content;
  margin-right: 12px;
}

.image-map {
  width: 8px;
  height: 11px;
}

.add-photo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
