<template>
  <v-container class="pa-0">
    <v-card tile elevation="0" class="pa-0">
      <v-card-title class="px-2 pt-0 text-h5 font-weight-bold black--text">
        Masukkan Data Kamu
      </v-card-title>
      <v-card-subtitle
        class="px-2 pt-2 text-subtitle-1 black--text"
        style="line-height: normal"
      >
        Silahkan lengkapi data di bawah ini. Akses lokasi akan otomatis terinput ketikan kamu menekan input "Tambah Alamat".
      </v-card-subtitle>
      <v-card-subtitle
        class="px-2 pt-0"
        style="line-height: normal"
      >
        <v-btn
          depressed
          small
          color="#C8E4FF"
          class="font-weight-bold primary--text"
          style="text-transform: unset !important"
          @click="infoPotongan = !infoPotongan"
        >
          <v-icon small left>mdi-alert-circle</v-icon>
          Info Biaya Layanan
        </v-btn>
      </v-card-subtitle>
    </v-card>

    <v-dialog
      v-model="infoPotongan"
      :transition="transition"
      persistent
      class="rounded-lg"
      max-width="450"
    >
      <v-card tile elevation="0" class="rounded-lg pb-3">
        <v-toolbar dense tile elevation="0">
          <v-btn small icon @click="infoPotongan = !infoPotongan">
            <v-icon small color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="py-0 text-h6 font-weight-bold black--text">
          Info Potongan
        </v-card-title>
        <v-card-subtitle class="py-4 text-subtitle-2 black--text">
          Pemol versi web ini dibuat untuk kamu yang menggunakan iOS atau tidak bisa menggunakan aplikasi Android. Biaya layanan <strong>sebesar 15% maksimal Rp20.000</strong> akan dipotong langsung dari hasil jual sampah kamu. Gunakan aplikasi Pemol di Playstore untuk bisa menabung, cashback, produk digital dan biaya layanan lebih murah. Biaya penjemputan gratis!
        </v-card-subtitle>

        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                depressed
                block
                outlined
                color="#EB4646"
                style="text-transform: unset !important"
                @click.prevent="openPlayStore"
              >
                Download Pemol
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                depressed
                block
                dark
                color="#EB4646"
                style="text-transform: unset !important"
                @click="infoPotongan = !infoPotongan"
              >
                Mengerti
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    infoPotongan: false,
    transition: "dialog-bottom-transition",
    linkPlaystore:
      "https://play.google.com/store/apps/details?id=com.tdb.pemol.pemol",
  }),
  methods: {
    openPlayStore() {
      window.location.href = this.linkPlaystore;
    },
  },
};
</script>