<template>
  <v-container class="py-0 px-5">
    <v-list-item class="pa-0">
      <v-list-item-avatar size="28" class="mr-0">
        <v-btn text small color="#232424" @click.prevent="back">
          <v-icon size="20"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0">
        <logo-top />
      </v-list-item-content>
    </v-list-item>
    <top />
    <Form />
  </v-container>
</template>

<script>
import Top from "@/components/Order/Top.vue";
import Form from "@/components/Order/Form.vue"
import LogoTop from '../components/Home/LogoTop.vue';

export default {
  name: "Order",

  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Order",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  components: {
    Top,
    Form,
    LogoTop,
  },

  methods: {
    back() {
      this.$router.push({ name: "katalog_sampah" });
    }
  },

  created() {
    var dataSampah = localStorage.getItem('dataSampah');
    if(dataSampah === null){
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
